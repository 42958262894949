import React from 'react'
import PrevNextNav from '../PrevNextNav'
import posed, { PoseGroup } from 'react-pose'

const Boxy = posed.div({
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ i }) => i * 40,
  },
  exit: { opacity: 0, y: 80 },
})

const Questions = ({ state, nextHandler, previousHandler }) => {
  return (
    <div className="col-md-6 pl-md-2  p-0">
      <div className="c-question-group-wrapper p-3 bg-white p-md-0">
        <div className="row no-gutters c-questions-group">
          <PoseGroup>
            {state.filteredQuestions.edges.map((edge, index) => {
              return (
                <Boxy i={index} key={edge.node.id} className="col-md-6 mb-4">
                  <article className="c-question pr-2">
                    <div className="c-question__type c-sober-mini-title">
                      {edge.node.frontmatter.question_type}
                    </div>
                    <h2 className="c-question__title mb-1">
                      {edge.node.frontmatter.title}
                    </h2>
                    <a
                      href={edge.node.frontmatter.question_link}
                      className="c-question__action c-read-more"
                    >
                      Read more
                    </a>
                  </article>
                </Boxy>
              )
            })}
          </PoseGroup>
          <footer className="w-100 pr-3">
            <div className="c-questions-nav w-50 d-flex align-items-center border-top pr-4">
              <div className="c-questions-nav__pager">
                <PrevNextNav
                  prevDisabled={0 >= state.currentIndex}
                  prevHandler={previousHandler}
                  nextDisabled={state.currentIndex >= state.maxIndex}
                  nextHandler={nextHandler}
                  sober={true}
                />
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Questions

/*

 <a href="#" className="c-read-more text-uppercase ml-2">
                View all questions
              </a>
 */
