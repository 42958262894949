import React, {Component} from 'react'

import './style.scss'

import $ from 'jquery'

import 'bootstrap';

import edge_bundling from './img/screenshot-edgebundling.png'
import kpi from './img/screenshot-kpi.png'
import summarytable from './img/screenshot-summarytable.png'
import excel from './img/screenshot-excel.png'
import powerpoint from './img/screenshot-powerpoint.png'

export default class DataStoriesLaptop extends Component {
    constructor(props) {
        super(props)

        this.slideDuration = 3000 // in ms
        this.carousel_laptop = React.createRef();

    }

    state = {}

    async componentDidMount() {
        let node = this.carousel_laptop.current;
        // init carousel
        $(() => {
            $(node).carousel();
        });

    }


    render() {
        return (
            <div id="carousel-laptop" className="carousel slide carousel-margin carousel-fade"
                 data-interval={this.slideDuration} data-ride="carousel" ref={this.carousel_laptop}>
                <div className="carousel-inner laptop-background" role="listbox">
                    <div className="carousel-item active">
                        <img src={edge_bundling} className="img-fluid" alt="" title=""/>
                    </div>
                    <div className="carousel-item">
                        <img src={kpi} className="img-fluid" alt="" title=""/>
                    </div>
                    <div className="carousel-item">
                        <img src={summarytable} className="img-fluid" alt="" title=""/>
                    </div>
                    <div className="carousel-item">
                        <img src={excel} className="img-fluid" alt="" title=""/>
                    </div>
                    <div className="carousel-item">
                        <img src={powerpoint} className="img-fluid" alt="" title=""/>
                    </div>
                </div>
            </div>
        )
    }
}
