import React, { Component } from 'react'
import { ReactComponent as Arrow } from '../../gfx/arrow-down.svg'

export default class Interest extends Component {
  state = { hover: false }
  render() {
    const { data: interest, isActive, onClick } = this.props

    return (
      <button
        onMouseOver={() => {
          this.setState({ hover: true })
        }}
        onMouseOut={() => {
          this.setState({ hover: false })
        }}
        onClick={onClick}
        className={`c-interest ${isActive && 'is-active'} `}
      >
        {this.state.hover || isActive ? (
          <img
            src={interest.icon_hover.publicURL}
            className="c-interest__icon"
          />
        ) : (
          <img src={interest.icon.publicURL} className="c-interest__icon" />
        )}

        <div className="c-interest__title">{interest.title}</div>
        <Arrow className="c-interest__arrow d-block d-md-none ml-1" />
      </button>
    )
  }
}
